// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_backdrop__HzBxV {\n  \n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 20;\n  background-color: rgba(0, 0, 0, 0.75);\n}\n\n.Modal_modal__RajTW {\n  position: fixed;\n  top: 15vh;\n  left: 5%;\n  width: 90%;\n  background-color: white;\n  padding: 1rem;\n  border-radius: 14px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  z-index: 30;\n  animation: Modal_slide-down__tDgXQ 300ms ease-out forwards;\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  .Modal_modal__RajTW {\n    width: 40rem;\n    left: calc(50% - 20rem);\n  }\n}\n\n@keyframes Modal_slide-down__tDgXQ {\n  from {\n    opacity: 0;\n    transform: translateY(-3rem);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/UI/Modal.module.css"],"names":[],"mappings":"AAAA;;EAEE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,UAAU;EACV,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,yCAAyC;EACzC,WAAW;EACX,0DAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;IACZ,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF","sourcesContent":[".backdrop {\n  \n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 20;\n  background-color: rgba(0, 0, 0, 0.75);\n}\n\n.modal {\n  position: fixed;\n  top: 15vh;\n  left: 5%;\n  width: 90%;\n  background-color: white;\n  padding: 1rem;\n  border-radius: 14px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n  z-index: 30;\n  animation: slide-down 300ms ease-out forwards;\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    width: 40rem;\n    left: calc(50% - 20rem);\n  }\n}\n\n@keyframes slide-down {\n  from {\n    opacity: 0;\n    transform: translateY(-3rem);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "Modal_backdrop__HzBxV",
	"modal": "Modal_modal__RajTW",
	"slide-down": "Modal_slide-down__tDgXQ"
};
export default ___CSS_LOADER_EXPORT___;
